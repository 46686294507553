// Angular
import { HTTP_INTERCEPTORS, HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
// RxJS
import { AuthService } from '@core/security/services/auth.service';
import { Store } from '@ngrx/store';
import { ValidateUtil } from '@utils/classes/ValidateUtil';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { ApiResponse } from '../../../@utils/models/ApiResponse';
import { CookiesService } from '../../../@utils/services/cookies.service';
import { MessageUtilService } from '../../../@utils/services/message-util.service';
import { Logout } from '../../store/actions/auth.actions';
import { AppState } from '../../store/index';

@Injectable({
	providedIn: 'root',
})
export class ErrorHandlerInterceptorService implements HttpInterceptor {
	refreshTokenInProgress = false;
	tokenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor(
		private readonly _authService: AuthService,
		private readonly _cookiesService: CookiesService,
		private readonly _messageUtilService: MessageUtilService,
		private _store: Store<AppState>
	) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			catchError((e: HttpErrorResponse) => {
				if ((e instanceof HttpErrorResponse && e.status === 403) || (e.status === 401 && typeof e.error !== 'undefined' && (e.error.codigo === '000046' || e.error.codigo === 'GEN000046'))) {
					if (!this.refreshTokenInProgress) {
						this.refreshTokenInProgress = true;
						this.tokenSubject.next(false);
						return this._authService.refreshToken().pipe(
							switchMap((authResponse) => {
								this.refreshTokenInProgress = false;
								this._authService.updateTokens(authResponse.idToken!, authResponse.accessToken!, authResponse.maestroSyncToken!);
								this.tokenSubject.next(true);
								return next.handle(this.getCloneRequest(req));
							}),
							catchError((e: HttpErrorResponse) => {
								this.refreshTokenInProgress = false;
								return throwError(e);
							})
						);
					} else {
						return this.tokenSubject.pipe(
							filter((idToken) => idToken !== false),
							take(1),
							switchMap((idToken) => {
								return next.handle(this.getCloneRequest(req));
							})
						);
					}
				}
				return this.throwError(e);
			})
		);
	}

	getCloneRequest(req: HttpRequest<any>) {
		let cloneRequest;
		if (req.url.indexOf('sumax-erp-backend-maestros-sync') !== -1) {
			cloneRequest = req.clone({
				headers: req.headers.set('Authorization', this._cookiesService.getBearerAuthorization('maestroSyncToken')),
			});
		} else if (req.url.indexOf('sumax-dms') != -1) {
			let headers = new HttpHeaders({ nroDocumento: '20414485554', usuario: 'TRACKING', clave: 'tracking212598' });
			return req.clone({ headers });
		} else {
			cloneRequest = req.clone({
				headers: req.headers.set('Authorization', this._cookiesService.getBearerAuthorization('idToken')),
			});
		}
		return cloneRequest;
	}

	private throwError(err: HttpErrorResponse) {
		if (!ValidateUtil.isUndefinedOrNull(err.status)) {
			if (err.status === 403 || err.status === 401) {
				if (typeof err.error !== 'undefined' && (err.error.codigo === '000046' || err.error.codigo === 'GEN000046')) {
					const errorResponse: ApiResponse = err.error;
					this._messageUtilService.getAlertError(errorResponse.codigo.toString(), errorResponse.titulo, errorResponse.mensaje);
				} else {
					if (err.message) {
						this._messageUtilService.getAlertError('A999', err.message);
					} else {
						this._messageUtilService.getAlertError('A999', 'Acceso Denegado');
					}
				}
			} else if (err.status === 500) {
				if (!ValidateUtil.isUndefinedOrNull(err.error) && err.error.type && err.error.type === 'A' && err.error.mensaje) {
					this._messageUtilService.getAlertWarning(err.error.mensaje);
				} else if (!ValidateUtil.isUndefinedOrNull(err.error) && err.error.type && err.error.type === 'E' && err.error.mensaje) {
					if (Array.isArray(err.error.data) && err.error.data.length != 0) {
						this._messageUtilService.getAlertErrorList(err.error.data);
					} else {
						this._messageUtilService.getAlertError('A999', err.error.mensaje);
						if (err.error.codigo === 'SRY100001') {
							this._store.dispatch(new Logout());
						}
					}
				} else if (ValidateUtil.isUndefinedOrNull(err.error) || err.status.toString() === err.error.codigo) {
					this._messageUtilService.getAlertError('A999', err.error && err.error.type && err.error.type != 'error' && err.error.mensaje ? err.error.mensaje : err.message);
				} else if (!ValidateUtil.isUndefinedOrNull(err.error) && !err.ok) {
					this._messageUtilService.getAlertError('A999', err.message);
				}
			} else if (err.status === 0 && err.statusText === 'Unknown Error') {
				console.log(err);
				this._messageUtilService.getAlertError('A999', `El servidor de búsqueda no responde<br>${err.url}`);
			} else {
				this._messageUtilService.getAlertError('A999', err.message);
			}
		}
		return throwError(err);
	}
}

export const errorHandlerInterceptorProviders = {
	provide: HTTP_INTERCEPTORS,
	useClass: ErrorHandlerInterceptorService,
	multi: true,
};
