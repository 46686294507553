import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SciSistema } from '~models/maestros-sync/config/SciSistema';
import { map } from 'rxjs/operators';
import { ApiResponse } from '@utils/models/ApiResponse';

const url = `${environment.HOST_MAESTROS_SYNC}/scisistemas`;
@Injectable({
	providedIn: 'root',
})
export class SciSistemaService {
	constructor(private http: HttpClient) {}

	findByCodigo(codigo: string): Observable<ApiResponse<SciSistema>> {
		return this.http.get<ApiResponse<SciSistema>>(`${url}/findByCodigo/${codigo}`);
	}

	findByCodigoNew(codigo: string): Observable<SciSistema> {
		return this.http.get<ApiResponse<SciSistema>>(`${url}/findByCodigo/${codigo}`).pipe(map((res) => res.data));
	}
}
