// NGRX
import { routerReducer } from '@ngrx/router-store';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { storeFreeze } from 'ngrx-store-freeze';

import { environment } from 'environments/environment';
import { authReducer, AuthState } from '@core/store/reducers/auth.reducers';
import { accesosReducer, AccesosState } from '@core/store/reducers/accesos.reducers';
import { oficinasReducer, OficinasState } from './reducers/oficinas.reducers';
import { menuReducer, MenuState } from './reducers/menu.reducers';
import * as fromRouter from '@ngrx/router-store';

// tslint:disable-next-line:no-empty-interface
export interface AppState {
	auth: AuthState;
	accesos: AccesosState;
	oficinas: OficinasState;
	menu: MenuState;
	router: fromRouter.RouterReducerState;
}

// export const reducers: ActionReducerMap<AppState> = {
export const reducers: ActionReducerMap<AppState> = {
	auth: authReducer,
	accesos: accesosReducer,
	oficinas: oficinasReducer,
	menu: menuReducer,
	router: routerReducer,
};

export const metaReducers: Array<MetaReducer<AppState>> = !environment.production ? [storeFreeze] : [];
