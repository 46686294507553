import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ApiResponse } from '@utils/models/ApiResponse';
import { Observable } from 'rxjs';
import { TbTipoCambio } from '~models/maestros-sync/maestros/TbTipoCambio';
import { SearchTbTipoCambioDto } from '~models/maestros-sync/search/SearchTbTipoCambioDto';

const url = `${environment.HOST_MAESTROS_SYNC}/tbtipocambios`;

@Injectable({
	providedIn: 'root',
})
export class TbTipoCambioService {
	constructor(private http: HttpClient) {}

	findLatestByMonedaAndFchCambio(searchTbTipoCambioDto: SearchTbTipoCambioDto): Observable<TbTipoCambio> {
		return this.http.post<ApiResponse<TbTipoCambio>>(`${url}/findLatestByMonedaAndFchCambio`, searchTbTipoCambioDto).pipe(map((res) => res.data));
	}
}
