import { BrowserModule, Title } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ThemeModule } from '@theme/theme.module';
import { AppRoutingModule } from './app.routing';
import { HttpClientModule } from '@angular/common/http';
import { LocationStrategy, HashLocationStrategy, registerLocaleData } from '@angular/common';
import { PartialsModule } from '@partials/partials.module';
import { TranslateModule } from '@ngx-translate/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule, RouterState } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

/*Cookies*/
import { CookieService } from 'ngx-cookie-service';
import { authInterceptorProviders } from '@core/security/interceptors/auth-interceptor.service';
import { errorHandlerInterceptorProviders } from '@core/security/interceptors/error-handler-interceptor.service';
import { EffectsArray } from '@core/store/effects';
import { metaReducers, reducers } from '@core/store';

import localeEs from '@angular/common/locales/es';
import { environment } from 'environments/environment';
import { IMqttServiceOptions, MqttModule } from 'ngx-mqtt';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SwUpdateNotifierService } from '@utils/services/sw-update-notifier.service';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';

registerLocaleData(localeEs, 'es');
// eslint-disable-next-line @typescript-eslint/naming-convention
const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
	hostname: environment.MQTTCONFIG.hostname,
	port: environment.MQTTCONFIG.port,
	username: environment.MQTTCONFIG.username,
	password: environment.MQTTCONFIG.password,
	protocol: environment.MQTTCONFIG.protocol,
	path: environment.MQTTCONFIG.path,
	keepalive: environment.MQTTCONFIG.keepalive,
};

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		ThemeModule,
		HttpClientModule,
		AppRoutingModule,
		PartialsModule,
		NgxMaskDirective,
		NgxMaskPipe,
		// METRONIC
		TranslateModule.forRoot(),
		MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
		// NGRX
		StoreModule.forRoot(reducers, { metaReducers }),
		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
		EffectsModule.forRoot(EffectsArray),
		StoreRouterConnectingModule.forRoot({
			stateKey: 'router',
			routerState: RouterState.Minimal,
		}),
		StoreDevtoolsModule.instrument({ connectInZone: true }),
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.production,
			registrationStrategy: 'registerWhenStable:30000',
		}),
	],
	providers: [
		{
			provide: LocationStrategy,
			useClass: HashLocationStrategy,
		},
		{
			provide: LOCALE_ID,
			useValue: 'es',
		},
		Title,
		CookieService,
		SwUpdateNotifierService,
		authInterceptorProviders,
		errorHandlerInterceptorProviders,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
