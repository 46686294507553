/* eslint-disable @typescript-eslint/explicit-function-return-type */
// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@core/security/guards/auth.guard';
import { BaseComponent } from '@theme/base/base.component';
import { ErrorPageComponent } from '@theme/error-page/error-page.component';
import { FormularioEnum } from '~shared/enums/Formulario.enum';

const routes: Routes = [
	{
		path: 'guia-remision',
		component: BaseComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: 'dashboard',
				data: { titulo: 'Dashboard | Guía remisión' },
				loadChildren: () => import('app/modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
			},
			{
				path: 'gre',
				data: {
					titulo: 'Guia remisión electrónica | Guía remisión',
					formulario: FormularioEnum.SGR_GuiaRemision,
				},
				loadChildren: () => import('app/modules/gestion/gre/gre.module').then((m) => m.GreModule),
			},
			{
				path: 'config-contactos',
				data: { titulo: 'Configuración de contactos | Guía remisión' },
				loadChildren: () => import('app/modules/herramientas/config-contactos/config-contactos.module').then((m) => m.ConfigContactosModule),
			},
			{ path: 'error/:type', component: ErrorPageComponent },
			{ path: '', redirectTo: `/guia-remision/gre`, pathMatch: 'full' },
			{ path: '**', redirectTo: '../error/403', pathMatch: 'full' },
		],
	},
	{
		path: 'error/403',
		component: ErrorPageComponent,
		data: {
			type: 'error-v6',
			code: 403,
			title: 'Prohibido pasar',
			desc: 'Tranquilo ratón, aún no desarrollamos esto.<br> Please, contact Mauricio.',
		},
	},
	{
		path: 'error/acceso-denegado',
		component: ErrorPageComponent,
		data: {
			type: 'error-v6',
			code: 403,
			title: 'Prohibido pasar',
			desc: 'No tiene permisos para ver esta página',
		},
	},
	{ path: '**', redirectTo: 'guia-remision/gre', pathMatch: 'full' },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			initialNavigation: 'disabled',
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
