<!-- <div
	class="kt-subheader kt-grid__item sumax-header-title"
	[ngClass]="{ 'kt-container--clear': clear }"
	id="kt_subheader">
	<div class="kt-container" [ngClass]="{ 'kt-container--fluid': fluid }">
		<div class="kt-subheader__main">
			<img
				class="kt-subheader__title"
				src="assets/media/logos/sumax-erp.svg"
				alt=""
				style="height: 30px" />
			<h5 class="sumax-titulo my-auto">{{ title }}</h5>
			<span class="kt-subheader__separator kt-subheader__separator--v"></span>
			<h4 class="kt-subheader__desc sumax-subtitle">{{ desc }}</h4>
		</div>
	</div>
</div> -->

<!-- <div class="kt-header-title"> -->
<div class="kt-header-title-logo">
	<img class="kt-header-title-logo-image" src="assets/media/logos/sumax-erp.svg" alt="sumax guía remisión" />
	<h5 class="kt-header-title-logo-text">{{ title }}</h5>
	<span class="kt-header-title-logo-separator"></span>
</div>
<h4 class="kt-header-title-text">{{ desc }}</h4>
<!-- </div> -->
