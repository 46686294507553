import { environment } from 'environments/environment';

// Angular
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// RxJS
import { Observable, defer, of } from 'rxjs';
import { filter, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
// NGRX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
// Auth actions
import { AuthActionTypes, Login, Logout, UserLoaded, UserRequested } from '../actions/auth.actions';

import { AppState } from '../../store';

import { AuthService } from '../../security/services/auth.service';
import { User } from '../models/user.model';
import { currentUser, isUserLoaded } from '../selectors/auth.selectors';

import { CookiesService } from '@utils/services/cookies.service';
import { SciSistema } from '~models/maestros-sync/config/SciSistema';
import { SciSistemaService } from '~services/maestros-sync/sciSistema.service';
import { TbUbicacionGeograficaService } from '~services/maestros-sync/tbUbicacionGeografica.service';
import { TbEntidadLocalesService } from '~services/maestros/tbEntidadLocales.service';
import { TbTransportistasService } from '~services/maestros/tbTransportistas.service';
import * as oficinasActions from '../actions/oficinas.actions';

@Injectable({
	providedIn: 'root',
})
export class AuthEffects {
	private _returnUrl = '';

	constructor(
		private actions$: Actions,
		private router: Router,
		private auth: AuthService,
		private store: Store<AppState>,
		private cookiesService: CookiesService,
		private sciSistemaService: SciSistemaService,
		private tbTransportistaService: TbTransportistasService,
		private tbUbicacionGeograficaService: TbUbicacionGeograficaService,
		private tbEntidadLocalesService: TbEntidadLocalesService
	) {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this._returnUrl = event.url;
			}
		});
	}

	login$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType<Login>(AuthActionTypes.Login),
				tap((action) => {
					localStorage.setItem(environment.searchTbEntidadDto.razonSocial, action.payload.user.sciEmpresa.razonSocial!);
					localStorage.setItem(environment.searchTbEntidadDto.nroDocumento, action.payload.user.sciEmpresa.nroDocumento!);
					this.router.initialNavigation();
					this.store.dispatch(new UserRequested());
				})
			),
		{ dispatch: false }
	);

	logout$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType<Logout>(AuthActionTypes.Logout),
				tap(() => {
					this.cookiesService.removeItem(environment.authTokenKey);
					this.cookiesService.removeItem(environment.idToken);
					this.cookiesService.removeItem(environment.refreshToken);
					this.cookiesService.removeItem(environment.maestroSyncToken);

					localStorage.removeItem(environment.notifications);
					window.location.href = environment.URL_LOGIN;
				})
			),
		{ dispatch: false }
	);

	loadUser$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType<UserRequested>(AuthActionTypes.UserRequested),
				withLatestFrom(this.store.pipe(select(isUserLoaded))),
				filter(([action, _isUserLoaded]) => !_isUserLoaded),
				withLatestFrom(this.store.pipe(select(currentUser))),
				tap(([action, user]) => {
					if (user) {
						this.store.dispatch(new UserLoaded({ user: user }));
						this.store.dispatch(oficinasActions.cargarOficinas());
					}
				}),
				mergeMap((_) => this.tbUbicacionGeograficaService.findAllByNivelAndEstadoNgSelect(3, true)),
				withLatestFrom(this.store.pipe(select(currentUser))),
				tap(([locations, user]) => {
					const ubiTemp = locations.filter((f) => f.idUbicacionGeografica === user.sciEmpresa.idUbicacionGeografica);
					const ubicacionGeografica = this.tbEntidadLocalesService.getNombreUbicacionGeograficaByNivel(3, ubiTemp[0]);
					this.tbUbicacionGeograficaService.dataUbiGeoSubject.next(ubicacionGeografica);
					this.tbTransportistaService.findAllByNroDocumentoCustom(localStorage.getItem(environment.searchTbEntidadDto.nroDocumento));
				})
			),
		{ dispatch: false }
	);

	init$: Observable<Action> = createEffect(() =>
		defer(() => {
			const accessToken = this.cookiesService.getItem(environment.authTokenKey);
			const idToken = this.cookiesService.getItem(environment.idToken);
			const refreshToken = this.cookiesService.getItem(environment.refreshToken);

			let observableResult = of({ type: 'NO_ACTION' });

			if (idToken) {
				let usuarioAuth: User = new User();
				return of({})
					.pipe(
						mergeMap((_) => this.sciSistemaService.findByCodigoNew(environment.COD_SISTEMA)),
						tap((res: SciSistema) => localStorage.setItem('idSistema', res.idSistema.toString())),
						mergeMap((_) => this.auth.authorize(environment.COD_SISTEMA))
					)
					.pipe(
						tap((resp) => {
							if (resp.status === 'OK') {
								// Token válido y tiene acceso al sistema
								const { su, se } = resp;
								const tbPersona = su.tbPersona;
								usuarioAuth.id = su.idUsuario;
								usuarioAuth.username = su.usuario;
								usuarioAuth.correoElectronico = su.correoElectronico;
								usuarioAuth.tbPersona = su.tbPersona;
								usuarioAuth.sciEmpresa = { ...se };
								usuarioAuth.fullname = `${tbPersona.nombre} ${tbPersona.apellido1}`;
							}
						}),
						mergeMap(() =>
							of(
								new Login({
									authToken: accessToken,
									idToken: idToken,
									refreshToken: refreshToken,
									user: usuarioAuth,
								})
							)
						)
					);
			}

			this.router.initialNavigation();
			return observableResult;
		})
	);
}
