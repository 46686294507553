import { Router } from '@angular/router';
import { currentAccesos } from '@core/store/selectors/accesos.selectors';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError, tap, switchMap, filter, take, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import * as accesosActions from '../actions/accesos.actions';
import * as menuActions from '../actions/menu.actions';
import { AuthService } from '@core/security/services/auth.service';
import { select, Store } from '@ngrx/store';
import { AppState } from '@core/store';
import { oficinaSelected } from '../selectors/oficinas.selectors';
import { selectRouteData } from '../selectors/router.selectors';

@Injectable()
export class AccesosEffects {
	constructor(private actions$: Actions, private store: Store<AppState>, private auth: AuthService, private router: Router) {}

	cargarAccesos$ = createEffect(() =>
		this.actions$.pipe(
			ofType(accesosActions.cargarAccesos),
			withLatestFrom(this.store.pipe(select(oficinaSelected))),
			mergeMap(([action, office]) =>
				this.auth.findAllByCodigoSistemaAndIdOficina(office?.idOficina!).pipe(
					map((formularioAcciones) => accesosActions.cargarAccesosSuccess({ sciFormularioAccionListado: formularioAcciones })),
					catchError((err) => of(accesosActions.cargarAccesosError({ payload: err })))
				)
			)
		)
	);

	cargarAccesosSucces$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(accesosActions.cargarAccesosSuccess),
				tap((action) => this.store.dispatch(menuActions.cargarMenu()))
			),
		{ dispatch: false }
	);

	validarAccesoFormulario$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(accesosActions.validarAccesoFormulario),
				withLatestFrom(this.store.pipe(select(oficinaSelected))),
				switchMap(([action, oficina]) => this.store.select(currentAccesos)),
				tap((accesos) => {
					if (accesos.length > 0) {
						this.store
							.select(selectRouteData)
							.pipe(
								filter((route) => route != null),
								take(1),
								map((res) => {
									if (!res.formulario) return;
									if (accesos.length === 0 || !(accesos.filter((acc) => acc.codigo === res.formulario).length > 0)) {
										this.router.navigateByUrl('error/acceso-denegado');
									}
								})
							)
							.subscribe();
					} else {
						this.router.navigateByUrl('error/acceso-denegado');
					}
				})
			),
		{ dispatch: false }
	);
}
