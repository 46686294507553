export class SearchTbTipoCambioDto {
	idSistema?: number;
	idMoneda?: number;
	idMonedaConversion?: number;
	codMoneda?: string;
	codMonedaConversion?: string;

	codMons?: string[];
	fchCambio?: Date;
}
