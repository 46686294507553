// Angular
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
// Layout
import { LayoutConfigService } from '@core/_base/layout';
// Object-Path
import objectPath from 'object-path';
import { Observable, of } from 'rxjs';
import { TbTipoCambio } from '~models/maestros-sync/maestros/TbTipoCambio';
import { SearchTbTipoCambioDto } from '~models/maestros-sync/search/SearchTbTipoCambioDto';
import { TbTipoCambioService } from '~services/maestros-sync/tbTipoCambio.service';

@Component({
	selector: 'kt-footer',
	templateUrl: './footer.component.html',
	host: {
		class: 'kt-footer',
	},
	encapsulation: ViewEncapsulation.None,
})
export class FooterComponent implements OnInit {
	tipoCambio$: Observable<TbTipoCambio> = of();
	// Public properties
	today: number = Date.now();
	fluid!: boolean;

	/**
	 * Component constructor
	 *
	 * @param layoutConfigService: LayouConfigService
	 */
	constructor(private layoutConfigService: LayoutConfigService, private tbTipoCambioService: TbTipoCambioService) {}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		const config = this.layoutConfigService.getConfig();

		let searchTbTipoCambioDto = new SearchTbTipoCambioDto();
		searchTbTipoCambioDto.codMoneda = 'USD';
		searchTbTipoCambioDto.codMonedaConversion = 'PEN';
		searchTbTipoCambioDto.fchCambio = new Date();
		this.tipoCambio$ = this.tbTipoCambioService.findLatestByMonedaAndFchCambio(searchTbTipoCambioDto);

		// footer width fluid
		this.fluid = objectPath.get(config, 'footer.self.width') === 'fluid';
	}
}
