import { SciEmpresa } from '~models/config';
import { TbPersona } from '~models/config/TbPersona';
import { TbEntidad } from '~models/maestros/TbEntidad';

export class User {
	id: number = 0;

	accessToken: string | null = null;
	refreshToken: string | null = null;

	fullname: string = '';
	username: string = '';
	tbEntidad: Partial<TbEntidad> = <TbEntidad>{};
	tbPersona: Partial<TbPersona> = <TbPersona>{};
	sciEmpresa: Partial<SciEmpresa> = <SciEmpresa>{};
	correoElectronico: string = '';
	estado: boolean = false;
}
